import React, { useCallback, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"

import { Helmet } from "react-helmet"
import * as css from "./ProfileSubscription.module.css"
import { Container, Row, Col, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck, faPlus } from "@fortawesome/free-solid-svg-icons"

import log from "../../components/log"

import KtpImageDefault from "../../assets/ktp-layout/design-ktp-indo/ktp_image_default.webp"
import useAuth from "../../hooks/useAuth"
import { LoadingPageOnly } from "../../components/auth/LoadingPage"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import LoadingKtp from "../../components/form-create-id/LoadingKtp"
import ReactTooltip from "react-tooltip"
import ReactTimeAgo from "react-time-ago"
import Moment from "react-moment"

const ProfileSubscription = ({ }: any) => {
    /**
     * Constant
     */
    const apiEndpoint = process.env.APP_API_ENDPOINT
    const isDebug = (process.env.APP_DEBUG == 'true')
    const { isAuthenticated, isLoading, accessToken } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    /**
     * Declare state
     */
    const [isSubscriptionLoading, setIsSubscriptionLoading] = React.useState(true)
    const [isSubscriptionDataReady, setIsSubscriptionDataReady] = React.useState(false)
    const [isSubscriptionDataFailed, setIsSubscriptionDataFailed] = React.useState(false)
    const [subscription, setSubscription] = React.useState({
        is_in_pro: false,
        expired_date: null,
        day_left: null,
    })

    React.useEffect(() => {
        /**
         * Get KTP list on server
         */
        setIsSubscriptionLoading(true)
        let endpoint = "/api/profile/subscription"
        log('debug', 'Calling Profile Subscription API', {
            endpoint: endpoint,
        })

        const getInfo = async () => {
            try {
                const res = await axiosPrivate.get(endpoint)

                if (res.status === 200) {
                    setSubscription(res.data.data)
                    setIsSubscriptionDataReady(true)
                }
            } catch (err: any) {
                if (err?.response == undefined) {
                    log("error", "Gagal terhubung ke server, silahkan muat ulang halaman.", {
                        file: 'ProfileSubscription',
                        func: 'getInfo',
                        exception: err,
                    })

                    return false;
                }

                // Ignore token expired error
                if (err?.response?.status != 401) {
                    log("error", "Gagal saat mengambil data dari server, silahkan muat ulang halaman. Jika masih mengalami kendala silahkan login ulang.", {
                        endpoint: endpoint,
                        error: err,
                    })
                }
            }
        }
        getInfo()
    }, [isAuthenticated, accessToken])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Langganan</title>
            </Helmet>

            {isLoading && (
                <>
                    <LoadingPageOnly />
                </>
            )}
            {isAuthenticated && (
                <>
                    <Container className={css.section}>
                        <Row>
                            <Col md="6">
                                <h1>Langganan</h1>
                            </Col>
                            <Col md="6" className={css.textRight}></Col>
                        </Row>

                        <Row>
                            <Col>
                                {!isSubscriptionDataReady ? (
                                    <>
                                        <LoadingPageOnly />
                                    </>
                                ) : (
                                    <div className={css.packages}>
                                        {
                                            subscription.is_in_pro && subscription.expired_date != null ? (
                                                <>
                                                    <div className={css.isInProImage}>
                                                        <img src="https://bucket-prod.ktpanabul.com/assets/email/new_pro_user_illustration.png" alt="illustration" />
                                                    </div>
                                                    <div className={css.isInPro}>Akun Pro</div>
                                                    <div className={css.activeUntil}>Aktif sampai</div>
                                                    <div>
                                                        <Moment format="dddd, D MMMM YYYY">{subscription.expired_date * 1000}</Moment> ({subscription.day_left} hari lagi)
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={css.isNotInProImage}>
                                                        <img src="https://bucket-prod.ktpanabul.com/assets/email/new_pro_user_illustration.png" alt="illustration" />
                                                    </div>
                                                    <div className={css.isInPro}>Akun Basic</div>
                                                    <div className={css.activeUntil}>Aktif sampai</div>
                                                    <div>
                                                        -
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                )}
                            </Col>
                        </Row>

                    </Container>


                </>
            )}
        </>
    )
}

export default ProfileSubscription
